<template>
    <div></div>
</template>
<script>
export default {
  
  created(){
    let params = (new URL(document.location)).searchParams;
    let token = params.get("token");    
    this.verifyEmail(token)
  },
  methods:{
    async verifyEmail(token){
      try{
        let resp = await this.$store.dispatch('auth/verifyEmail', {token})
        if(resp.data){
          // tới trang thông báo
          location.href = '/verify-email-success'
        }else{
          location.href = process.env.VUE_APP_MEEY_LAND
        }
      }catch(err){
        if(err.error.message === "Phiên làm việc hết hạn"){
          location.href = '/verify-email-expired'
        }else{
          location.href = process.env.VUE_APP_MEEY_LAND ? process.env.VUE_APP_MEEY_LAND : '/'
        }
      }
    }
  }

  
}
</script>